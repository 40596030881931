<template>
  <b-row class="w-100">
    <b-col lg="12">
      <div class="user-registeration-inner w-100">
        <div class="user-registeration-services">
          <h6 class="font-size-20 user-registeration-label">{{ this.$t('joining.servicesType') }},</h6>
          <b-row>
            <b-col v-for="(item, index) in serviceTypes" :key="index" lg="6">
              <b-button @click="changeService(item.value)" class="user-registeration-services-btn d-block w-100" :class="{'active': selectedService === item.value}">{{item.label}}</b-button>
            </b-col>
          </b-row>
            <keep-alive>
              <component :is="selectedService"></component>
            </keep-alive>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import individual from './individual'
import comapny from './comapny'
export default {
  components: {
    comapny,
    individual
  },
  data () {
    return {
      selectedService: 'individual'
      // serviceTypes: [
      //   {
      //     label: this.$t('joining.independentDriver'),
      //     value: 'individual'
      //   },
      //   {
      //     label: this.$t('joining.shippingCompany'),
      //     value: 'comapny'
      //   }
      // ]
    }
  },
  computed: {
    serviceTypes () {
      return [
        {
          label: this.$t('joining.independentDriver'),
          value: 'individual'
        },
        {
          label: this.$t('joining.shippingCompany'),
          value: 'comapny'
        }
      ]
    }
  },
  methods: {
    changeService (type) {
      this.selectedService = type
    }
  }
}
</script>
