import { joining, transportation, veterinary } from '@/axios'

export default {
  join (payload) {
    return joining().post('merchant/register', payload)
  },
  joinDriver (payload) {
    return transportation().post('drivers', payload)
  },
  joinCompany (payload) {
    return transportation().post('companies', payload)
  },
  joinDoctor (payload) {
    return veterinary().post('doctors/register', payload)
  },
  joinClinic (payload) {
    return veterinary().post('clinics/register', payload)
  }
}
