i<template>
  <div class="h-100vh d-flex flex-column justify-content-center align-items-center user-registeration">
    <register-as :selectedComponent="selectedComponent" :selectedComponentTitle="selectedComponentTitle" @changeForm="changeComponent" />
    <transition name="router-anim" :enter-active-class="`animated ${animated.enter}`" mode="out-in"
                :leave-active-class="`animated ${animated.exit}`">
      <keep-alive>
        <component :is="selectedComponent" @changeForm="changeComponent"></component>
      </keep-alive>
    </transition>
  </div>
</template>
<script>
import registerAs from '../components/registerationSteps/registerAs'
import stores from './index'
import vete from '../components/registerationSteps/vete/vete'
import transportation from '../components/registerationSteps/transportation/transportation'
import { core } from '@/config/pluginInit'
export default {
  components: { registerAs, stores, vete, transportation },
  data () {
    return {
      animated: { enter: 'fadeInUp', exit: 'fadeOut' },
      selectedComponent: '',
      selectedComponentTitle: ''
      // allComponent: [
      //   { value: 'vete', name: 'خدمات البيطرة', title: 'الانضمام كتاجر او مقدم خدمة' },
      //   { value: 'transportation', name: 'خدمات النقل' },
      //   { value: 'stores', name: 'المتاجر' }
      // ],
      // options: [
      //   { label: 'المتاجر', value: 'stores' },
      //   { label: 'خدمات النقل', value: 'transportation' },
      //   { label: 'خدمات البيطرة', value: 'vete' },
      //   { label: 'خدمات التأمين', value: 'insurance' },
      //   { label: 'خدمات التصاريح', value: 'other' }
      // ]
    }
  },
  computed: {
    allComponent () {
      return [
        {
          value: 'vete',
          name: this.$t('endUser.veterinarian'),
          title: 'الانضمام كتاجر او مقدم خدمة'
        },
        {
          value: 'transportation',
          name: this.$t('endUser.transport')
        },
        {
          value: 'stores',
          name: this.$t('endUser.shops')
        }
      ]
    },
    options () {
      return [
        {
          label: this.$t('endUser.shops'),
          value: 'stores'
        },
        {
          label: this.$t('endUser.transport'),
          value: 'transportation'
        },
        {
          label: this.$t('endUser.veterinarian'),
          value: 'vete'
        },
        {
          label: this.$t('endUser.insurance'),
          value: 'insurance'
        },
        {
          label: this.$t('endUser.permissions'),
          value: 'other'
        }
      ]
    }
  },
  mounted () {
    core.index()
  },
  methods: {
    changeComponent (data) {
      console.log(data)
      const indexStepper = this.allComponent.findIndex(stepper => stepper.value === data)
      this.selectedComponent = this.allComponent[indexStepper].value
      this.selectedComponentTitle = this.allComponent[indexStepper].title
    }
  }
}
</script>
<style>

</style>
