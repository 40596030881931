<template>
  <b-row class="w-100">
    <b-col md="12">
      <h4 class="text-primary user-registeration-title" :class="{'decrease-margin': selectedComponentTitle}">{{selectedComponentTitle ? selectedComponentTitle : $t('joining.registerJoiningServices') }}</h4>
    </b-col>
    <b-col md="12">
      <div>
        <h6 class="text-primary font-size-20 user-registeration-label">{{ $t('joining.chooseServices') }}</h6>
        <main-select
          dir="rtl"
          :options="options"
          :placeholder="$t('joining.chooseServices') "
          :reduce="(item) => item.value"
          class="user-registeration-select"
          @input="onSelect"
        />
        <p  v-if="token == null" class="text-center mt-3 mb-1">  يجب أن تكون مسجلاً للدخول أولاً. إذا لم يكن لديك حساب. <router-link :to="{name: 'registration'}"><span class="text-warning"> برجاء إنشاء حساب جديد</span></router-link></p>
        <div class="d-flex justify-content-center" v-if="!selectedComponent">
          <b-button
            disabled
            @click="submit"
            variant="secondary"
            type="submit"
            class="px-5 py-2 iq-border-radius-5 text-center font-weight-bold user-registeration-btn">
            <span class="mx-2">{{ $t('joining.continue') }}</span>
          </b-button>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
/* eslint-disable */ 
export default {
  props: ['selectedComponent', 'selectedComponentTitle'],
  data () {
    return {
      token:'',
      selectedService: ''
      // options: [
      //   { label: 'المتاجر', value: 'stores' },
      //   { label: 'خدمات النقل', value: 'transportation' },
      //   { label: 'خدمات البيطرة', value: 'vete' },
      //   { label: 'خدمات التأمين', value: 'insurance' },
      //   { label: 'خدمات التصاريح', value: 'other' }
      // ]
    }
  },
  computed: {
    options () {
      return [
        { label: this.$t('endUser.shops'), value: 'stores' },
        { label: this.$t('endUser.transport'), value: 'transportation' },
        { label: this.$t('endUser.veterinarian'), value: 'vete' },
        { label: this.$t('endUser.insurance'), value: 'insurance' },
        // { label: this.$t('endUser.permissions'), value: 'other' }
      ]
    }
  },
  methods: {
    onSelect (e) {
      this.selectedService = e
      this.$emit('changeForm', e)
    },
    submit () {
      this.$emit('changeForm', this.selectedService)
    }
  },
  created() {
    this.token = localStorage.getItem('userToken');
  },
}
</script>
