<template>
  <b-row class="w-100">
    <b-col lg="12">
      <div class="user-registeration-inner w-100">
        <div class="user-registeration-services">
          <h6 class="font-size-20 user-registeration-label">{{ $t('joining.servicesType') }}</h6>
          <b-row>
            <b-col v-for="(item, index) in serviceTypes" :key="index" lg="6">
              <b-button @click="changeService(item.value)" class="user-registeration-services-btn d-block w-100" :class="{'active': selectedService === item.value}">{{item.label}}</b-button>
            </b-col>
          </b-row>
          <transition name="slide-up" appear mode="out-in" :duration="300" class="w-100">
            <keep-alive>
              <component :is="selectedService"></component>
            </keep-alive>
          </transition>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import individual from './individual'
import clinic from './clinic'
export default {
  components: {
    clinic,
    individual
  },
  data () {
    return {
      selectedService: 'individual'
      // serviceTypes: [
      //   {
      //     label: 'دكتور مستقل',
      //     value: 'individual'
      //   },
      //   {
      //     label: 'عيادة',
      //     value: 'clinic'
      //   }
      // ]
    }
  },
  computed: {
    serviceTypes () {
      return [
        {
          label: this.$t('joining.independentDoctor'),
          value: 'individual'
        },
        {
          label: this.$t('joining.clinic'),
          value: 'clinic'
        }
      ]
    }
  },
  methods: {
    changeService (type) {
      this.selectedService = type
    }
  }
}
</script>
