<template>
  <div class="user-registeration-service-box mt-4">
    <ValidationObserver v-slot="{ handleSubmit }">
      <form class="doctor-individual-form" @submit.prevent="handleSubmit(submit)">
        <input-form
          class="mb-3 inp"
          v-model="doctorData.full_name"
          validate="required"
          name="full_name"
          placeholder="الاسم بالكامل"
          label="الاسم بالكامل"
        />
        <input-form
          class="mb-3 inp"
          v-model="doctorData.email"
          validate="required|email"
          name="email"
          placeholder="البريد الالكترونى"
          label="البريد الالكترونى"
        />
        <country-code
          :validate="'required|numeric|min:9|max:11'"
          name="primary_phone_number"
          placeholder="رقم الهاتف"
          label="رقم الهاتف"
          v-model="doctorData.primary_phone_number"
          id="phone_number_individual"
          @onSelect="onSelect"
        />
        <country-code
          :validate="'numeric|min:9|max:11'"
          name="secondary_phone_number"
          placeholder="رقم هاتف اخر"
          label="رقم هاتف اخر"
          v-model="doctorData.secondary_phone_number"
          id="secondary_phone_number_individual"
          @onSelect="onSelect2"
        />
        <!-- <input-form
          class="mb-3 inp"
          v-model="doctorData.id"
          validate="required|numeric"
          name="id"
          placeholder="رقم الهوية"
          label="رقم الهوية"
        /> -->
        <div class="d-flex justify-content-center">
          <b-button
            v-if="!loading"
            variant="primary"
            type="submit"
            class="px-5 py-2 iq-border-radius-5 text-center font-weight-bold user-registeration-service-box-btn">
            <span class="mx-2"> التالى</span>
          </b-button>
          <b-button v-else class=" text-white px-5 py-2" variant="primary" disabled>
            <spinner-loading text="checking"/>
          </b-button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
// import { process.env.VUE_APP_FRONT_DOCTOR_VETERINARY_LINK} from '@/config/constant'
import { core } from '@/config/pluginInit'
import api from '../../../services/join'
export default {
  data () {
    return {
      loading: false,
      doctorData: {
        full_name: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).user.name : '',
        primary_phone_number: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).user.phone : '',
        country_code: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).user.country_code : '',
        country_code2: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).user.country_code : '',
        email: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).user.email : '',
        secondary_phone_number: '',
        type: 'individual'
      }
    }
  },
  methods: {
    onSelect (data) {
      this.doctorData.country_code = data
    },
    onSelect2 (data) {
      this.doctorData.country_code2 = data
    },
    submit () {
      this.loading = true
      api.joinDoctor(this.doctorData).then(res => {
        core.showSnackbar('success', 'doctor created successfully')
        window.location.href = process.env.VUE_APP_FRONT_DOCTOR_VETERINARY_LINK
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
