<template>
  <div class="user-registeration-service-box mt-4">
    <ValidationObserver v-slot="{ handleSubmit }">
      <form class="doctor-individual-form" @submit.prevent="handleSubmit(submit)">
        <input-form
          class="mb-3 inp"
          v-model="individualData.name"
          validate="required"
          name="full_name"
          placeholder="الاسم بالكامل"
          label="الاسم بالكامل"
        />
        <country-code
                validate="required"
                name="phone_number"
                placeholder="رقم الهاتف"
                label="رقم الهاتف"
                v-model="individualData.phone"
                id="phone_number_individual"
                @onSelect="onSelect"
                />
                <!-- class="mb-3 inp flex-1 flex-grow-1 phone-input" -->
        <div class="mb-3">

        <!-- <input-form
          class="mb-3 inp flex-1 flex-grow-1 phone-input"
          v-model="individualData.phone"
          validate="required"
          name="phone_number"
          placeholder="رقم الهاتف"
          label="رقم الهاتف"
        />
          <vue-country-code
              v-model="individualData.countryCode"
              @onSelect="onSelect"
              :onlyCountries="['sa', 'eg']"
              :dropdownOptions="{ disabledDialCode: true }"
              :enabledCountryCode= true
              defaultCountry="sa"
              class="testphone"
          /> -->
        </div>
        <input-form
          class="mb-3 inp"
          v-model="individualData.idNumber"
          validate="required|numeric"
          name="id"
          placeholder="رقم الهوية"
          label="رقم الهوية"
        />
        <div class="d-flex justify-content-center">
          <b-button
              v-if="!submitLoading"
            variant="primary"
            type="submit"
            class="px-5 py-2 iq-border-radius-5 text-center font-weight-bold user-registeration-service-box-btn">
            <span class="mx-2"> تسجيل</span>
          </b-button>
          <b-button
              v-else
              variant="primary"
              class="px-5 py-2 iq-border-radius-5 text-center font-weight-bold user-registeration-service-box-btn">
            <spinner-loading text="تحميل"/>
          </b-button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import joiningServices from '../../../services/join'
import { core } from '@/config/pluginInit'
export default {
  data () {
    return {
      submitLoading: false,
      individualData: {
        name: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).user.name : '',
        phone: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).user.phone : '',
        idNumber: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).user.national_id : '',
        countryCode: ''
      }
    }
  },
  methods: {
    onSelect (data) {
      // const countryCode = {
      //   20: '0020',
      //   966: '00966'
      // }
      this.individualData.countryCode = data
    },
    submit () {
      this.submitLoading = true
      joiningServices.joinDriver(this.individualData).then(res => {
        core.showSnackbar('success', res.data.message)
        this.$router.push('/user-profile/user-home')
      }).finally(() => {
        this.submitLoading = false
      })
    }
  }
}
</script>
<style>
.testphone{
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 9px !important;
  border-top-left-radius: 9px !important;
  margin-top: 37px;
}
</style>
